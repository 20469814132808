import { render, staticRenderFns } from "./Newsletter.vue?vue&type=template&id=0fe44442&"
import script from "./Newsletter.vue?vue&type=script&lang=ts&"
export * from "./Newsletter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-3/_work/8/a/NuxtApp/components/base/Input.vue').default,BaseFormItem: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-3/_work/8/a/NuxtApp/components/base/FormItem.vue').default,BaseButton: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-3/_work/8/a/NuxtApp/components/base/Button.vue').default,AppNoty: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-3/_work/8/a/NuxtApp/components/app/Noty.vue').default,BaseForm: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-3/_work/8/a/NuxtApp/components/base/Form.vue').default})
